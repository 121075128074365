<template>
    <router-view v-if="!loadingInit"/>
</template>
<style lang="scss">
    // 3rd party plugins css
    @import "~vue-select/src/scss/vue-select.scss";
    @import '~bootstrap-vue/dist/bootstrap-vue.css';
    @import '~perfect-scrollbar/css/perfect-scrollbar.css';
    @import '~animate.css';
    @import '~@fortawesome/fontawesome-free/css/all.css';

    @import 'assets/sass/style.vue';
</style>

<script>
    import { OVERRIDE_LAYOUT_CONFIG } from '@/modules/layout/layout-store';
    import { mapActions, mapGetters } from 'vuex';
    import { eventHub } from '@/shared/utils/eventHub';

    export default {
        name: 'App',
        computed: {
            ...mapGetters({
                loadingInit: 'auth/loadingInit',
                listPeriod: 'notifications/inApp/listPeriod',
                isAuthenticated: 'auth/isAuthenticated',
                isAtlasPublicView: 'shared/isAtlasPublicView',
            })
        },
        methods: {
            ...mapActions({
                doInit: 'auth/doInit',
                changeTokenState: 'auth/setExpiredToken',
                fetchSettings: 'shared/fetchSettings',
            }),
            detectRecomandedScreen() {
                if (!window.matchMedia('(min-width: 1280px)')?.matches && !this.isAtlasPublicView) {
                     this.$alert({
                        type: 'warning',
                        text: this.$t('GENERAL.RECOMANDED_SCREEN'),
                        showConfirmButton: false,
                    });
                }
            }
        },
        mounted() {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static json (@/core/config/layout.config.json)
             */
            this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

        },
        async created() {
            if (this.isAuthenticated) {
                await this.fetchSettings();
            }
            await this.doInit();
            eventHub.$on('expiredToken', (tokenState) => {
                this.changeTokenState(tokenState);
                this.$bvModal.show('login-modal');
            });
            this.detectRecomandedScreen();
        },
    };
</script>
