<template>
    <div>
        <div
            v-can:create="'application'"
            class="row justify-content-center mx-5 py-5">
            <st-button
                variant="secondary"
                :block="true"
                :callback="createRequest"
                id="btn_create_request"
                customClass="text-dark application-add-button"
            >
                <i class="fas fa-plus text-dark application-btn-icon"/>
                <span class="application-create-btn-text">{{ $t('MENU.CREATE_REQUEST_BUTTON') }}</span>
            </st-button>
        </div>
        <ul class="menu-nav custom-menu-nav">
            <!-- Root -->
            <!-- Dashboard menu item available after BE is done -->
            <router-link
                v-if="hasPermissionsToViewDashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/dashboard">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-th-large"/>
                        <span class="menu-text">{{ $t('MENU.DASHBOARD') }}</span>
                    </a>
                    <ul>
                        <li class="menu-item menu-item-submenu menu-item-open">
                            <div class="menu-submenu menu-submenu-classic menu-submenu-right ">
                                <ul v-if="hasPermissionsToViewReports" class="menu-subnav">
                                    <router-link
                                        to="/dashboard-reports"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[isExactActive && 'menu-item-active']"
                                            >
                                             <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-arrow-right"><span></span></i>
                                                <span class="menu-text"> {{ $t('MENU.DASHBOARD_REPORTS') }}</span>
                                            </a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </router-link>
            <router-link
                v-can:read="'applications'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/applications">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-file"/>
                        <span class="menu-text">{{ $t('MENU.MY_APPLICATIONS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="hasPermissionToViewApplications"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/new-applications">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-comment-alt"/>
                        <span class="menu-text">{{ $t('MENU.INBOX') }}</span>
                    </a>
                    <ul>
                        <li class="menu-item menu-item-submenu menu-item-open">
                            <div class="menu-submenu menu-submenu-classic menu-submenu-right ">
                                <ul class="menu-subnav">
                                    <router-link
                                        v-can:read="'applicationsStaffOwn'"
                                        to="/applications-assigned"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[
                                                isExactActive && 'menu-item-active'
                                            ]"
                                        >
                                            <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-solid fa-signature"><span></span></i>
                                                <span class="d-flex align-items-center" v-if="assignedApplicationsCount">
                                                    <i class="fas fa-circle font-size-xs text-danger mr-2"></i>
                                                </span>
                                                <span class="menu-text">
                                                    {{ $t('MENU.STAFF_OWN_APPLICATIONS') }}
                                                </span>
                                            </a>
                                        </li>
                                    </router-link>
                                    <router-link
                                        v-can:read="'applicationsStaffOwn'"
                                        to="/applications-in-work"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[
                                                isExactActive && 'menu-item-active'
                                            ]"
                                        >
                                            <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-tasks"><span></span></i>
                                                <span class="d-flex align-items-center" v-if="assignedApplicationsCount">
                                                    <i class="fas fa-circle font-size-xs text-danger mr-2"></i>
                                                </span>
                                                <span class="menu-text">
                                                    {{ $t('MENU.IN_WORK') }}
                                                </span>
                                            </a>
                                        </li>
                                    </router-link>
                                    <router-link
                                        v-can:read="'applicationsStaffOwn'"
                                        to="/applications-in-clarifications"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[
                                                isExactActive && 'menu-item-active'
                                            ]"
                                        >
                                            <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-exclamation-circle"><span></span></i>
                                                <span class="d-flex align-items-center" v-if="assignedApplicationsCount">
                                                    <i class="fas fa-circle font-size-xs text-danger mr-2"></i>
                                                </span>
                                                <span class="menu-text">
                                                    {{ $t('MENU.IN_CLARIFICATIONS') }}
                                                </span>
                                            </a>
                                        </li>
                                    </router-link>
                                    <router-link
                                        v-can:read="'applicationsStaffOwn'"
                                        to="/applications-completed"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[
                                                isExactActive && 'menu-item-active'
                                            ]"
                                        >
                                            <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fas fa-check-circle"><span></span></i>
                                                <span class="d-flex align-items-center" v-if="assignedApplicationsCount">
                                                    <i class="fas fa-circle font-size-xs text-danger mr-2"></i>
                                                </span>
                                                <span class="menu-text">
                                                    {{ $t('MENU.COMPLETED') }}
                                                </span>
                                            </a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </router-link>
            <router-link
                v-if="hasPermissionToViewApplications"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/applications">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-file"/>
                        <span class="menu-text">{{ $t('MENU.REGISTRY') }}</span>
                    </a>
                    <ul>
                        <li class="menu-item menu-item-submenu menu-item-open">
                            <div class="menu-submenu menu-submenu-classic menu-submenu-right ">
                                <ul class="menu-subnav">
                                    <router-link
                                        v-if="hasPermissionToViewApplications"
                                        v-slot="{ href, navigate, isActive, isExactActive }"
                                        to="/applications">
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[
                                                isExactActive && 'menu-item-active'
                                            ]"
                                        >
                                            <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-eye"><span></span></i>
                                                <span class="d-flex align-items-center" v-if="assignedApplicationsCount">
                                                    <i class="fas fa-circle font-size-xs text-danger mr-2"></i>
                                                </span>
                                                <span class="menu-text">
                                                    {{ $t('MENU.GENERAL_REGISTRY') }}
                                                </span>
                                            </a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </router-link>
            <router-link
                v-can:read="'issuedDocument'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/issued-documents"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-folder"/>
                        <span class="menu-text">{{ $t('MENU.ISSUED_DOCUMENT') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'issuedDocumentStaff'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/issued-documents"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-folder"/>
                        <span class="menu-text">{{ $t('MENU.ISSUED_DOCUMENT_STAFF') }}</span>
                    </a>
                </li>
            </router-link>
            <!-- Remove condition once complains are ready to use -->
            <router-link
                v-can:read="'complain'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/complain">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-exclamation-circle"/>
                        <span class="menu-text">{{ $t('MENU.MY_COMPLAINS') }}</span>
                    </a>
                </li>
            </router-link>

            <router-link
                v-can:read="'complainStaff'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/complain">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-exclamation-circle"/>
                        <span class="menu-text">{{ $t('MENU.STAFF_COMPLAINS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'control'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/controls">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-file-signature"/>
                        <span class="menu-text">{{ $t('MENU.CONTROLS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'paymentRegistry'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/payments"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-credit-card"/>
                        <span class="menu-text">{{ $t('MENU.PAYMENT_REGISTRY') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/internal-documents"
                v-can:read="'internalDocuments'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                    >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-file"></i>
                        <span class="menu-text">{{ $t('MENU.INTERNAL_DOCUMENTS') }}</span>
                    </a>
                    <ul>
                        <li class="menu-item menu-item-submenu menu-item-open">
                            <div class="menu-submenu menu-submenu-classic menu-submenu-right ">
                                <ul class="menu-subnav">
                                    <router-link
                                        v-can:read="'internalDocumentsOwn'"
                                        to="/sign-internal-documents"
                                        v-slot="{ href, navigate, isExactActive }"
                                    >
                                        <li
                                            aria-haspopup="true"
                                            data-menu-toggle="hover"
                                            class="menu-item"
                                            :class="[isExactActive && 'menu-item-active']"
                                            >
                                             <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-icon fas fa-arrow-right"></i>
                                                <span class="d-flex align-items-center" v-if="internalDocumentsCount">
                                                    <i class="fas fa-circle font-size-xs text-danger mr-2"></i>
                                                </span>
                                                <span class="menu-text"> {{ $t('MENU.STAFF_SIGN_INTERNAL_DOCUMENTS') }} ({{ internalDocumentsCount }})</span>
                                            </a>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/document-series-allocation"
                v-can:read="'documentSeriesAllocation'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-file"></i>
                        <span class="menu-text">{{ $t('MENU.DOCUMENT_SERIES_ALLOCATION') }}</span>
                    </a>
                </li>
            </router-link>
            <!-- END Root -->
            <!-- Organisation config -->
            <li v-if="viewStaffOrganisationAdministration || isSuperAdmin">
                <hr/>
            </li>
            <li class="menu-section" v-if="viewStaffOrganisationAdministration || isSuperAdmin">
                <h4 class="menu-text">{{ $t('MENU.ORGANISATION_CONFIG') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-can:read="'organisations'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/institutions"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-sitemap"></i>
                        <span class="menu-text">{{ $t('MENU.ORGANIZATION')}}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="viewStaffOrganisationAdministration"
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="myInstitutionUrl"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-sitemap"></i>
                        <span class="menu-text">{{ $t('MENU.MY_ORGANIZATION')}}</span>
                    </a>
                </li>
            </router-link>
            <li v-if="isSuperAdmin">
                <hr/>
            </li>
            <!-- END Organisation config -->
            <!-- System config -->
            <li class="menu-section" v-if="isSuperAdmin">
                <h4 class="menu-text">{{ $t('MENU.SYSTEM_CONFIG') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-can:read="'processEditor'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/process-editor"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-cogs"></i>
                        <span class="menu-text">{{ $t('MENU.PROCESS_MANAGEMENT')}}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'accounts'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/accounts"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-users"></i>
                        <span class="menu-text">{{ $t('MENU.ACCOUNTS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'beneficiaries'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/beneficiaries"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-people-arrows"></i>
                        <span class="menu-text">{{ $t('MENU.BENEFICIARIES') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'taxCategory'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/tax-category"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-hand-holding-usd"></i>
                        <span class="menu-text">{{ $t('MENU.TAX_CATEGORY') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'complainType'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/complain-type"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.COMPLAIN_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/application-type"
                v-can:read="'applicationType'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.APPLICATION_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="document-series-type"
                v-can:read="'documentSeriesType'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.DOCUMENT_SERIES_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/control-type"
                v-can:read="'controlType'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.CONTROL_TYPE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'forms'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/forms">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fab fa-wpforms"/>
                        <span class="menu-text">{{ $t('MENU.FORMS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="viewDocumentsTemplates"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/document-template">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-copy"/>
                        <span class="menu-text">{{ $t('MENU.DOCUMENT_TEMPLATE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'responseTemplates'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/response-templates"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fas fa-exclamation-circle"></i>
                        <span class="menu-text">{{ $t('MENU.RESPONSE_TEMPLATES') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'notificationsTemplate'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/notifications-templates"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-bell"/>
                        <span class="menu-text">{{ $t('MENU.NOTIFICATIONS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/responsibilities"
                v-can:read="'responsibility'"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon  fas fa-people-carry"></i>
                        <span class="menu-text">{{ $t('MENU.RESPONSIBILITIES') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'permissions'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/permissions">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-user-lock"/>
                        <span class="menu-text">{{ $t('MENU.PERMISSIONS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'metadataMapping'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/metadata-mapping">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-map-signs"/>
                        <span class="menu-text">{{ $t('MENU.METADATA_MAPPING') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/payments">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-credit-card"/>
                        <span class="menu-text">{{ $t('MENU.PAYMENTS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/documents">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-folder"/>
                        <span class="menu-text">{{ $t('MENU.DOCUMENTS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'decisionPurpose'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/decision-purpose-list"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-list"></i>
                        <span class="menu-text">{{ $t('MENU.PURPOSE_LIST') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'decisionTarget'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/decision-target-list"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-list"></i>
                        <span class="menu-text">{{ $t('MENU.TARGET_LIST') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-can:read="'systemSettings'"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/system-settings"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-cogs"></i>
                        <span class="menu-text">{{ $t('MENU.SYSTEM_SETTINGS') }}</span>
                    </a>
                </li>
            </router-link>
            <li>
                <hr/>
            </li>
            <!-- END System config -->
            <li class="menu-section" v-if="isStaff">
                <h4 class="menu-text">{{ $t('MENU.EXTERNAL_APPS') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-slot="{ href, isActive, isExactActive }"
                to="/atlas"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('atlas')">
                        <i class="menu-icon fas fa-globe-europe"/>
                        <span class="menu-text">{{ $t('MENU.ATLAS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="isStaff"
                v-can:read="'archive'"
                v-slot="{ href, isActive, isExactActive }"
                to="/archive-dashboard"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('archive')">
                        <i class="menu-icon fas fa-archive"/>
                        <span class="menu-text">{{ $t('MENU.ARCHIVE') }}</span>
                    </a>
                </li>
            </router-link>
            <li v-if="!isStaff">
                <hr/>
            </li>
             <!-- Help Section -->
            <li class="menu-section" v-if="!isStaff">
                <h4 class="menu-text">{{ $t('MENU.HELP') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/manual">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon flaticon2-open-text-book"/>
                        <span class="menu-text">{{ $t('MENU.MANUAL') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="!isStaff"
                v-slot="{ href, isActive, isExactActive }"
                to="/faq">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('faq')">
                        <i class="menu-icon far fa-question-circle"/>
                        <span class="menu-text">{{ $t('MENU.FAQ') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="!isStaff"
                v-slot="{ href, isActive, isExactActive }"
                to="/applications-details">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('applications_details')">
                        <i class="menu-icon fas fa-th-list"/>
                        <span class="menu-text">{{ $t('MENU.APPLICATIONS_DETAILS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/gdpr">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-book"/>

                        <span class="menu-text">GDPR</span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import { ApplicationTypePermissions } from "@/modules/application-type/application-type-permissions";
    import { InstitutionsPermissions } from "@/modules/administrator/institutions-permissions";
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
    import { DashboardPermissions } from '@/modules/dashboard/dashboard-permissions';
    import { DocumentTemplatePermissions } from '@/modules/document-template/document-template-permissions';
    import { InternalDocumentsPermissions } from '@/modules/internal-documents/internal-documents-permissions';

    const LANDING_PAGES =  {
        faq: '/intrebari-frecvente',
        applications_details: '/'
    }
    export default {
        name: 'MenuGU',
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                accountDetails: 'auth/accountDetails',
                assignedApplicationsCount: 'assignedApplicationsCount',
                appConfig: "shared/appConfig",
                isStaff: 'auth/isStaff',
                isSimpleStaff: 'auth/isSimpleStaff',
                isAdminStaff: 'auth/isAdminStaff',
                isSuperAdmin: 'auth/isSuperAdmin',
                internalDocumentsCount: 'internalDocumentsCount',
            }),
            hasPermissionToApplicationType() {
                return new ApplicationTypePermissions(this.currentUser).read;
            },
            hasPermissionToOwnOrganisation() {
                return new InstitutionsPermissions(this.currentUser).viewOwnOrganisation;
            },
            myInstitutionUrl() {
                return `/institution?id=${this.accountDetails?.organisation_id}&tab=institution-details`;
            },
            hasPermissionToOwnStaffApplications() {
                return new ApplicationsPermissions(this.currentUser).readOwnStaff;
            },
            hasPermissionToViewOrgApplications() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            hasPermissionToViewApplications() {
                return this.hasPermissionToViewOrgApplications || this.hasPermissionToOwnStaffApplications
            },
            hasPermissionsToViewDashboard() {
                return new DashboardPermissions(this.currentUser).view;
            },
            hasPermissionsToViewReports() {
                return new DashboardPermissions(this.currentUser).viewReports;
            },
            viewStaffOrganisationAdministration() {
                return this.hasPermissionToOwnOrganisation && this.isAdminStaff;
            },
            hasPermissionsToReadDocumentsTemplates() {
                return new DocumentTemplatePermissions(this.currentUser).read;
            },
            viewDocumentsTemplates() {
                return this.hasPermissionsToReadDocumentsTemplates && this.isSuperAdmin;
            },
            hasPermissionToInternalDocuments() {
                return new InternalDocumentsPermissions(this.currentUser).read;
            }
        },
        methods: {
            ...mapActions({
                getAssignedApplications: 'getAssignedApplicationsCount',
            }),
            hasActiveChildren(match) {
                return this.$route.path.indexOf(match) !== -1;
            },
            createRequest() {
                this.$router.push({ name: 'applicationNew'})
            },
            navigateTo(item) {
                if (LANDING_PAGES[item]) {
                    window.open(`${window.VUE_APP_LANDING_PAGE_URL}${LANDING_PAGES[item]}`, '_blank');
                }
                this.$emit('navigate', item);
            }
        },
        async created() {
            const payload = {
                params: {
                    skip: 0,
                    limit: this.appConfig.RECORDS_PER_PAGE,
                    pagination: true,
                },
            };

            if (this.isSimpleStaff && this.hasPermissionToOwnStaffApplications) {
                if (payload.params.internal) {
                    delete payload.params.internal;
                }

                payload.params.assigned = true;
                //get assigned applications or the ones which need to be signed
                await this.getAssignedApplications(payload);
            }

            if (this.isSimpleStaff && this.hasPermissionToInternalDocuments) {
                payload.params.assigned = true;
                payload.params.internal = true;

                //get assigned applications or the ones which need to be signed
                await this.getAssignedApplications(payload);
            }
        }
    };
</script>
