import Layout from "@/modules/layout/components/layout";
import { storeAsync } from "../../app-module";

const ApplicationsManager = () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/components/ApplicationsManager.vue");
const LayerManager = () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/components/LayerManager.vue");
const InterestAreasManager = () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/components/InterestAreasManager.vue");

const Atlas = () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/pages/Atlas.vue");
const AtlasPublicView = () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/pages/AtlasPublicView.vue");

const getAtlasModule = () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/atlas-store");
const importAtlasStore = () =>
    getAtlasModule().then((storeModule) => {
        if (!storeAsync().hasModule("atlas"))
            storeAsync().registerModule("atlas", storeModule.default);
        else return;
    });

export default [
    {
        path: "/atlas-public-view",
        component: AtlasPublicView,
        meta: { unauth: false },
        beforeEnter: (to, from, next) => {
            importAtlasStore().then(() => {
                next();
            });
        },
    },
    {
        path: "/atlas",
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: "atlas",
                path: "/",
                redirect: "/atlas/layers",
                component: Atlas,
                meta: {
                    auth: true,
                    isAtlas: true,
                },
                beforeEnter: (to, from, next) => {
                    importAtlasStore().then(() => {
                        next();
                    });
                },
                children: [
                    {
                        name: "layerManager",
                        path: "layers",
                        component: LayerManager,
                        meta: {
                            auth: true,
                            isAtlas: true,
                        },
                    },
                    {
                        name: "applicationsManager",
                        path: "applications",
                        component: ApplicationsManager,
                        meta: {
                            auth: true,
                            isAtlas: true,
                        },
                    },
                    {
                        name: "interestAreas",
                        path: "interest-areas",
                        component: InterestAreasManager,
                        meta: {
                            auth: true,
                            isAtlas: true,
                        },
                    },
                ],
            },
        ],
    },
];
